module.exports = {
  app: {
    apiKey: "AIzaSyAmFtE3-OBzdwfRJFmb4ErRXnAUyrtyoDk",
    authDomain: "soteria-fc697.firebaseapp.com",
    databaseURL: "https://soteria-fc697.firebaseio.com",
    projectId: "soteria-fc697",
    storageBucket: "soteria-fc697.appspot.com",
    messagingSenderId: "334446806091",
    appId: "1:334446806091:web:50d173a870048f7c6279ce"
  },
  tokens: ["9iTLbhua259gbbYj8Ymn4u3NcjL6OYph1Q+kRa0jR4C6o1YApc30YVX03t0FYrbM/bB3EUxC4XNPb0Egdek4Cw=="],
  gtm_id: "GTM-N2MC97M",
  statitics_url: "https://datastudio.google.com/embed/reporting/64bb8d23-fb5b-4027-a9dc-02fa4015d284/page/DVWQB",
  fcm_key: "BHQIknLhaNOcarr6tre5ADSDvj-OBvxtf687wxrgDkuHiMoF5dp6_CAnw5tQa1LTB2EN2mo6u3Xk9coJlGlaF8w",
  contactStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    {
      id: "desestimado",
      name: "Desestimado",
      reasons: [
        "Precio alto",
        "Falta de presupuesto",
        "Morosidad dificil manejar",
        "Junta de propietarios no desea, por invertir en otro gasto",
        "Invertir en su propio software",
        "Utilizan otro software",
        "Mejoras en el app cuando se compara con la  competencia.",
        "Soporte 24/07",
        "Recaudación multibanco",
        "Acceso a camaras de video vigilancia",
        "EXTRANJERO( Clientes bucan referencia en base cartera de clientes)",
        "Datos inválidos",
        "Otros",
      ]
    },
    { id: "curioso", name: "Curioso" },
    { id: "cliente", name: "Cliente" },
  ],
  contactImportData: [
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Tipo de documento", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  contactExportData: [
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Tipo de documento", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "País", key: "country", width: 20, outlineLevel: 1, type: "string" },
    { header: "Región/Provincia", key: "city", width: 20, outlineLevel: 1, type: "string" },
    { header: "Localidad/Ciudad", key: "province", width: 20, outlineLevel: 1, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "¿Acepto términos y condiciones?", key: "terms", type: "terms", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  contactFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) => 
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    document_type: {
      type: "select",
      label: "Documento",
      items: ["DNI", "Carnet Extranjero", "RUC"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    country: {
      type: "select",
      label: "País",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    city: {
      type: "select",
      label: "Región/Provincia",
      items: [],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    province: {
      type: "text",
      label: "Localidad/Ciudad",
      placeholder: "Localidad/Ciudad",
      flex: { xs: 12, sm: 6, md: 4 },
      show_on_detail: true,
      form_update: true,
    },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    newsletter: {
      type: "checkbox",
      label: "Desea recibir ofertas y promociones de Soteria",
      flex: { xs: 12 },
      class: "pt-5",
    },
    terms: {
      type: "checkbox",
      label: "Aceptó los términos y condiciones de Soteria",
      flex: { xs: 12 },
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  tableContact:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Email",
      align: "center",
      sortable: false,
      value: "email",
    },
    {
      text: "País",
      align: "center",
      sortable: false,
      value: "country",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  contactActivityImportData: [
    { header: "Correo electrónico", key: "contact_email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
    { header: "Correo del encargado", key: "agent_id", width: 20, type: "agent" },
  ],
  saleImportData: [
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Dirección", key: "address", width: 20, outlineLevel: 1, type: "string" },
    { header: "Producto", key: "products", width: 20, type: "product" },
    { header: "Cantidad", key: "quantity", width: 20, type: "product_detail" },
    { header: "Precio", key: "price", width: 20, type: "product_detail" },
    { header: "Pago total", key: "total_paid", width: 20, type: "number" },
    { header: "Método de pago", key: "payment_method", width: 20, type: "string" },
    { header: "Estado del pago", key: "paid_status", width: 20, type: "string" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  saleExportData: [
    { header: "Id", key: "id", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Dirección", key: "address", type: "string", width: 20 },
    { header: "Zona", key: "zone", type: "string", width: 20 },
    { header: "Producto/Cantidad/Precio", key: "products", type: "products", width: 30},
    { header: "Cupón/Valor", key: "coupon", type: "coupon", width: 20},
    { header: "Estado de pago", key: "paid_status", type: "string", width: 20 },
    { header: "Método de pago", key: "payment_method", type: "string", width: 20 },
    { header: "Total de compra", key: "total_paid", type: "number", width: 20 },
    { header: "Fecha de compra", key: "created_at", type: "timestamp", width: 20 },
  ],
  salePaidStatus: [
    { name: "PAGO EXITOSO", type: "success" },
    { name: "PROCESANDO PAGO", type: "procesing" },
    { name: "ERROR CULQI", type: "error" },
    { name: "ERROR VISANET", type: "error" },
  ],
  ticketStates: [
    { id: "nuevo", name: "Nuevo" },
    { id: "interesado", name: "Interesado" },
    { id: "sin-respuesta", name: "Sin respuesta" },
    { id: "desestimado", name: "Desestimado" },
    { id: "curioso", name: "Curioso" },
    { id: "marketing", name: "Marketing" },
    { id: "cliente", name: "Cliente" },
  ],
  tableTicket:  [
    {
      text: "Contacto",
      align: "center",
      sortable: false,
      value: "full_name",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "state",
    },
    {
      text: "Registrado",
      align: "center",
      value: "created_at",
      sortable: true,
    },
    {
      text: "Origen",
      align: "center",
      sortable: false,
      value: "origin",
    },
    {
      text: "Encargado",
      align: "center",
      sortable: false,
      value: "agent_id",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  ticketFormSchema: {
    ticket_name: {
      type: "text",
      label: "Nombre del ticket",
      placeholder: "Nombre del ticket",
      flex: { xs: 12 },
      show_on_detail: true,
      form_update: true,
    },
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12, sm:6 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
      no_edit: true,
    },
    phone: {
      type: "text",
      label: "Teléfono",
      placeholder: "Teléfono",
      flex: { xs: 12, sm: 6 },
      rules: [
        (v) => 
          // eslint-disable-next-line
          /^$|^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(v) || "Teléfono no válido",
      ],
      show_on_detail: true,
      form_update: true,
    },
    document_type: {
      type: "select",
      label: "Documento",
      items: ["DNI", "Carnet Extranjero", "RUC"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    document_number: {
      type: "text",
      label: "Número de documento",
      placeholder: "Número de documento",
      flex: { xs: 12, sm: 6 },
      show_on_detail: true,
      form_update: true,
    },
    subject: { type: "text", label: "Asunto", placeholder: "Asunto", flex: { xs: 12 }, show_on_detail: true, form_update: true },
    comment: { type: "text", label: "Comentario", placeholder: "Comente aquí", flex: { xs: 12 } },
    agent: { type: "select", label: "Encargado", items: [], placeholder: "Seleccione", flex: { xs: 12, sm: 6 } },
    medium_contact: { 
      type: "select",
      label: "Medio de contacto",
      items: ["Whatsapp", "Facebook", "Instagram", "Llamada", "Correo", "Consulta", "Web", "Evento", "Sala de ventas"],
      placeholder: "Seleccione",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    tags: {
      type: "hidden",
      label: "Etiquetas",
      flex: { xs: 12 },
    },
  },
  ticketImportData: [
    { header: "Nombre del Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Apellidos", key: "last_name", width: 30, outlineLevel: 1, type: "string" },
    { header: "Correo electrónico", key: "email", width: 20, outlineLevel: 1, type: "email" },
    { header: "Teléfono", key: "phone", width: 20, outlineLevel: 1, type: "string" },
    { header: "Documento", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, type: "string" },
    { header: "Asunto", key: "subject", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Correo del Encargado", key: "agent_id", width: 20, type: "agent" },
    { header: "Origen", key: "origin", width: 20, type: "string" },
  ],
  ticketExportData: [
    { header: "Ticket", key: "ticket_name", type: "string", width: 30 },
    { header: "Nombres", key: "name", type: "string", width: 30 },
    { header: "Apellidos", key: "last_name", type: "last_name", width: 30 },
    { header: "Correo electrónico", key: "email", type: "email", width: 30 },
    { header: "Teléfono", key: "phone", type: "string", width: 20 },
    { header: "Documento", key: "document_type", width: 20, outlineLevel: 1, type: "string" },
    { header: "Número de documento", key: "document_number", width: 20, outlineLevel: 1, type: "string" },
    { header: "País", key: "country", width: 20, outlineLevel: 1, type: "string" },
    { header: "Región/Provincia", key: "city", width: 20, outlineLevel: 1, type: "string" },
    { header: "Localidad/Ciudad", key: "province", width: 20, outlineLevel: 1, type: "string" },
    { header: "Asunto", key: "subject", width: 20, type: "string" },
    { header: "Comentario", key: "comment", width: 20, type: "string" },
    { header: "Estado", key: "state", type: "string", width: 20 },
    { header: "Etiquetas", key: "tags", width: 20, type: "tags" },
    { header: "Encargado", key: "agent_id", type: "agent", width: 20 },
    { header: "Fecha de registro", key: "created_at", type: "timestamp", width: 20 },
    { header: "Última actividad", key: "last_comment", type: "string", width: 20 },
    { header: "Fecha de última actividad", key: "last_activity", type: "timestamp", width: 20 },
    { header: "Origen", key: "origin", type: "string", width: 20 },
  ],
  ticketActivityImportData: [
    { header: "ID del Ticket", key: "ticket_id", width: 20, outlineLevel: 1, type: "string" },
    { header: "Tipo de actividad", key: "activity_type", width: 20, type: "activity" },
    { header: "Comentario", key: "comment", width: 40, type: "string" },
    { header: "Correo del encargado", key: "agent_id", width: 20, type: "agent" },
  ],
  agentTable:  [
    {
      text: "Encargado",
      align: "left",
      value: "full_name",
    },
    {
      text: "Correo electrónico",
      align: "center",
      value: "email",
    },
    {
      text: "Rol",
      align: "center",
      sortable: false,
      value: "role",
    },
    {
      text: "Operación",
      align: "center",
      sortable: false,
      value: "operations",
    },
  ],
  agentFormSchema: {
    name: {
      type: "text",
      label: "Nombres",
      placeholder: "Nombres",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    last_name: {
      type: "text",
      label: "Apellidos",
      placeholder: "Apellidos",
      flex: { xs: 12, sm: 6 },
      rules: [ (v) => !!v || "Campo requerido" ],
      show_on_detail: true,
      form_update: true,
    },
    email: {
      type: "email",
      label: "Correo electrónico",
      placeholder: "Correo electrónico",
      flex: { xs: 12 },
      rules: [
        (v) => !!v || "Campo requerido",
        (v) =>
          // eslint-disable-next-line
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Correo electrónico no válido",
      ],
      show_on_detail: true,
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#1a9dde",
        // secondary: "#e158a0",
        secondary: "#ffc107",
        accent: "#607d8b",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      },
      dark: {
        primary: "#607d8b",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      }
    }
  }
};
